import React from "react"
import { Box, Flex, Stack } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Container,
  Layout,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  SecondaryPagesHero,
  Seo,
  SidebarLinks,
} from "../../components/elements"

const datoCmsCancerGivingOpportunity = ({ data, pageContext }) => {
  const page = data.datoCmsCancerGivingOpportunity
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo title={page.title} description={page.introContent} />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      <Container width="full">
        <Stack
          direction={["column", "column", "row"]}
          spacing={12}
          py={[9, 9, 12, 16]}
        >
          <Main width={["full", "full", "2 / 3"]}>
            {/* Content */}
            <Flex flexWrap="wrap">
              <Box width="full">
                <Box
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Main>

          <Box
            as="aside"
            id="sidebar"
            bg="lightgray"
            width={["full", "full", 1 / 3]}
          >
            <Box p={[3, 3, 6]}>
              <Box as="h3">A Closer Look</Box>
              <SidebarLinks page={page} />
            </Box>
          </Box>
        </Stack>
      </Container>
    </Layout>
  )
}

export default datoCmsCancerGivingOpportunity

export const query = graphql`
  query givingOpportunitiesPageQuery($slug: String!) {
    datoCmsCancerGivingOpportunity(slug: { eq: $slug }) {
      id
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      introContent
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      linksNode {
        childMarkdownRemark {
          html
        }
      }
      meta {
        status
      }
      slug
      title
      seo {
        description
        title
        image {
          url
        }
      }
    }

    allDatoCmsCancerEvent {
      edges {
        node {
          id
          title
          slug
          introContent
        }
      }
    }
  }
`
